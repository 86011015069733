import {useEffect, useState} from 'react';
import React from 'react';
import {twMerge} from 'tailwind-merge';
import cx from 'classnames';

import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';
import {
  InteractiveSceneV2,
  InteractiveSceneV2AreaChoice,
} from '@/components/player-v2/InteractiveSceneV2/types';
import {getOptionOverlayClassName} from '@/components/player-v2/InteractiveVoting/utils';
import {Description} from '@/components/player-v2/InteractiveVoting/OptionOverlayVideoPlayer';
import MemoizedInteractiveSceneV2Cutscene from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Cutscene';
import useInteractiveProgressBar from '@/components/player-v2/InteractiveSceneV2/hooks/useInteractiveProgressBar';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {useWindowSize} from '@/utils/hooks/useWindowSize';
import {
  getVideoHeight,
  getVideoPositionFromScreenLeft,
  getVideoPositionFromScreenTop,
  getVideoWidth,
} from '@/utils/video-player';
import {ProjectVideoMetadata} from '../types';

type Props = {
  active: boolean;
  scene: InteractiveSceneV2;
  metadata: ProjectVideoMetadata;
  setSubtitleWithDuration: (text: string | null, duration: number) => void;
  playAudio: (url: string) => void;
  stopAudio: () => void;
  onChoiceEnd: (choice: InteractiveSceneV2AreaChoice) => void;
  onSkip: () => void;
  isFullScreen?: boolean;
};

const MemoizedContinueButton = React.memo(function ContinueButton({
  onSkip,
}: {
  onSkip: () => void;
}) {
  const {seekTo} = usePlayer();
  const {playedSeconds: timestamp} = usePlayerProgress();
  const [continueButtonPos, setContinueButtonPos] = useState(0);

  // TODO: Calculate based off of video height and width, which may not
  // be full screen
  const {windowWidth, windowHeight} = useWindowSize();

  useEffect(() => {
    setContinueButtonPos(
      getVideoPositionFromScreenTop(windowWidth, windowHeight) + 32
    );
  }, [windowWidth, windowHeight]);

  return (
    <div
      className="fixed right-8 z-30"
      style={{
        bottom: `${continueButtonPos}px`,
      }}
    >
      <ButtonV2
        size="sm"
        text="Continue video"
        onClick={(e) => {
          e.preventDefault();
          seekTo(timestamp + 1);
          onSkip();
        }}
      />
    </div>
  );
});

const MemoizedInteractiveSceneV2AreaChoices = React.memo(
  function InteractiveSceneV2AreaChoices({
    onSkip,
    active,
    metadata,
    scene,
    setSubtitleWithDuration,
    playAudio,
    stopAudio,
    onChoiceEnd,
    isFullScreen = true,
  }: Props) {
    // console.log('Rerendering InteractiveSceneV2AreaChoices');
    const {timer, resetTimer} = useInteractiveProgressBar({
      duration: scene.duration * 1000,
      enabled: active,
    });
    const {windowWidth, windowHeight} = useWindowSize();

    const bgCutScene = scene.during?.video || null;

    const [selectedChoice, setSelectedChoice] =
      useState<InteractiveSceneV2AreaChoice | null>(null);

    useEffect(() => {
      if (100 - timer <= 0) {
        resetTimer();
        // choose the default index choice
        if (scene.defaultChoice && scene.areaChoices) {
          onChoiceEnd(scene.areaChoices[scene.defaultChoice]);
        }
      }
    }, [timer, scene]);

    if (!scene.areaChoices) {
      return null;
    }

    const choiceOverlayClassName = getOptionOverlayClassName(
      scene.areaChoices.length
    );

    const showChoices = active && scene.areaChoices.length > 0;

    return (
      <div
        className={cx(
          isFullScreen ? 'h-screen w-screen fixed' : 'h-full w-full relative',
          'top-0',
          showChoices ? 'visible' : 'hidden'
        )}
      >
        {
          <div
            className={twMerge(
              'w-full bg-opacity-75 absolute bottom-40 duration-500 h-1 rounded-sm bg-shib-white z-40 transition-none',
              scene.duration === Infinity ? 'hidden' : 'visible'
            )}
            style={{transform: `scale3d(${100 - timer}%, 1, 1)`}}
          />
        }
        {scene.skippable ? <MemoizedContinueButton onSkip={onSkip} /> : null}
        <MemoizedInteractiveSceneV2Cutscene
          metadata={metadata}
          blend={bgCutScene?.blend}
          active={showChoices}
          url={bgCutScene?.url || ''}
          loop={!!bgCutScene?.loop}
          muted={bgCutScene?.muted || false}
          shouldHideControls
        />
        {scene.areaChoices.map((option, idx) => {
          const isActive = showChoices && selectedChoice === option;
          let descriptionElement = null;
          if (option.label) {
            const descriptionPositionY =
              getVideoPositionFromScreenTop(windowWidth, windowHeight) +
              option.label?.y * getVideoHeight(windowWidth, windowHeight);

            const descriptionPositionX =
              getVideoPositionFromScreenLeft(windowWidth, windowHeight) +
              option.label?.x * getVideoWidth(windowWidth, windowHeight);

            descriptionElement = (
              <Description
                key={`description_${idx}`}
                className={isActive ? 'visible' : 'hidden'}
                top={descriptionPositionY}
                left={descriptionPositionX}
                text={option.label?.text}
              />
            );
          }

          return (
            <React.Fragment key={`cutscene_${idx}`}>
              {option.during?.video ? (
                <MemoizedInteractiveSceneV2Cutscene
                  metadata={metadata}
                  blend={option.during.video.blend}
                  url={option.during.video.url || ''}
                  loop={option.during.video.loop || false}
                  active={isActive}
                  shouldHideControls
                />
              ) : null}
              {descriptionElement}
            </React.Fragment>
          );
        })}
        <div className="absolute inset-0 flex h-screen w-screen z-20">
          {scene.areaChoices.map((option, idx) => {
            return (
              <button
                key={'overlay_' + idx}
                className={twMerge(choiceOverlayClassName, 'bg-transparent')}
                onClick={() => {
                  resetTimer();
                  onChoiceEnd(option);
                  stopAudio();
                  setSubtitleWithDuration(null, 0);
                }}
                onMouseLeave={() => {
                  setSelectedChoice(null);
                  stopAudio();
                  setSubtitleWithDuration(null, 0);
                }}
                onMouseEnter={() => {
                  setSubtitleWithDuration(null, 0);
                  if (option.during?.audio?.url) {
                    stopAudio();
                    playAudio(option.during?.audio?.url);
                    if (option.during?.audio?.subtitle) {
                      setSubtitleWithDuration(
                        option.during?.audio?.subtitle,
                        option.during?.audio?.duration
                      );
                    }
                  }
                  setSelectedChoice(option);
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default MemoizedInteractiveSceneV2AreaChoices;
