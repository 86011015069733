import {ReactNode, useEffect, useState} from 'react';
import cx from 'classnames';

import {BasePlayer, BasePlayerProps} from '@/components/player-v2/BasePlayer';
import {BasicPlayerOverlay} from '@/components/player-v2/BasicPlayerOverlay';
import {PlayerConfig, usePlayer} from '@/components/player-v2/PlayerProvider';
import {PlayerUrl} from '@/components/player-v2/types';

type OptionCutsceneVideoPlayerProps = {
  isSelected: boolean;

  // The url of the video
  url: PlayerUrl;

  isParentVideoMuted: boolean;
  playerProps?: Omit<BasePlayerProps, 'url'>;

  cutsceneEndingScreen: ReactNode | undefined;
  forceMute?: boolean;
  isFullScreen?: boolean;
  id?: string;
} & PlayerConfig;

export const OptionCutsceneVideoPlayer: React.FC<
  OptionCutsceneVideoPlayerProps
> = ({
  playerProps,
  isSelected,
  url,
  isParentVideoMuted,
  cutsceneEndingScreen,
  forceMute = false,
  isFullScreen = true,
  id = '',
}) => {
  const {isPlaying, mute, togglePlay, toggleMute, isMuted, hasEnded, seekTo} =
    usePlayer();
  const [showEndScreen, setShowEndScreen] = useState(false);

  useEffect(() => {
    if ((isSelected && isPlaying) || (!isSelected && !isPlaying)) {
      return;
    }
    if (!isSelected) {
      // seek back to the beginning
      seekTo(0);
    }
    togglePlay();
  }, [isSelected]);

  useEffect(() => {
    if (forceMute) {
      mute();
    }
    if ((isParentVideoMuted && !isMuted) || (!isParentVideoMuted && isMuted)) {
      toggleMute();
    }
  }, [isParentVideoMuted, forceMute]);

  useEffect(() => {
    if (hasEnded) {
      // Show the cutscene ending screen
      setShowEndScreen(true);
    } else {
      setShowEndScreen(false);
    }
  }, [hasEnded]);

  return (
    <div
      className={cx(
        isFullScreen ? 'w-screen h-screen' : 'w-full h-full',
        'relative'
      )}
    >
      <BasePlayer
        url={url}
        {...playerProps}
        fullScreen={isFullScreen}
        isActive={isSelected}
      />
      <BasicPlayerOverlay
        name={`cutscene-${id}-overlay`}
        className="absolute top-0 h-full w-full"
      />
      {showEndScreen && cutsceneEndingScreen}
    </div>
  );
};
