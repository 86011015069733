import React from 'react';

import FadeIn from '@/components/FadeIn';
import {InteractiveSceneOption} from '@/components/player-v2/InteractiveVoting/types';

export const SubtitleContainer = ({
  className = '',
  option,
}: {
  className?: string;
  option: InteractiveSceneOption | null;
}) => {
  if (!option || !option.audioSubtitle) {
    return null;
  }

  return (
    <div
      className={`${className} absolute bottom-16 z-10 flex w-full items-center justify-center`}
    >
      <FadeIn delay={200}>
        <p className="Text--subtitle z-50 text-3xl font-bold">
          {option.audioSubtitle}
        </p>
      </FadeIn>
    </div>
  );
};

export const SubtitleOverlay = ({
  className = '',
  option,
}: {
  className?: string;
  option: InteractiveSceneOption | null;
}) => {
  return (
    <div
      className={`${className} pointer-events-none absolute inset-0 items-center justify-center`}
    >
      <SubtitleContainer option={option} />
    </div>
  );
};

export default SubtitleOverlay;
