import ButtonV2 from '@/components/redesign/ButtonV2';
import React from 'react';

// an individual choice option
const MemoizedInteractiveSceneV2Button = React.memo(
  function InteractiveSceneV2Button({
    text,
    disabled,
    selected,
    rejected,
    onClick,
  }: {
    disabled?: boolean;
    text: string;
    selected: boolean;
    rejected: boolean;
    onClick: () => void;
  }) {
    const finalDisabled = selected || rejected || disabled;
    return (
      <ButtonV2
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
        disabled={finalDisabled}
        className={`${selected ? 'border' : 'border-none'} ${
          rejected ? 'bg-shib-black' : ''
        } ${
          disabled ? '' : 'hover:scale-105 focus:scale-105'
        } text--TrebuchetMS focus:shadow-lg font-bold hover:shadow-lg break-words transition-all duration-300 text-glow shadow-none`}
        category="secondary-translucent"
        text={text}
      />
    );
  }
);

export default MemoizedInteractiveSceneV2Button;
