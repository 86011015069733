import {useEffect, useState} from 'react';
import cx from 'classnames';
import {InteractiveScene, InteractiveSceneOption} from './types';
import {getVideoPositionFromScreenTop} from '@/utils/video-player';

import {usePlayerProgress} from '../PlayerProgressProvider';
import {usePlayer} from '../PlayerProvider';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {useWindowSize} from '@/utils/hooks/useWindowSize';

/* The controller calculates state based upon timestamp and viewer interactions. 
Since this component depends on usePlayerProgress, it will rerender on every timestamp change.
UI components are passed in as memoized props to prevent them from rerendering unnecessarily. */

export const getNextScene = (
  ts: number,
  interactiveScenes: {[ts: number]: InteractiveScene}
): number | 'end' | null => {
  const sortedScenes = Object.keys(interactiveScenes).sort((aStr, bStr) => {
    const a = parseFloat(aStr);
    const b = parseFloat(bStr);
    return a - b;
  });
  const nextSceneToSet = sortedScenes.find((key) => {
    const float = parseFloat(key);
    return float > ts;
  });
  return parseFloat(nextSceneToSet ?? '') ?? 'end';
};

export const InteractiveVotingController = ({
  interactiveScenes,
  nextScene,
  setNextScene,
  activePlayer,
  setActivePlayer,
  selectedOption,
  mainOverlay,
  optionsCutsceneOverlay,
  optionsAudioOverlay,
  optionsVideoOverlay,
  contextOverlay,
  isPreview,
  bandersnatchOverlay,
  checkKeyOverlay,
  producerPassOverlay,
  backButtonOverlay,
  isFullScreen = true,
}: {
  interactiveScenes: {[ts: number]: InteractiveScene};
  nextScene: InteractiveScene | 'end' | null;
  setNextScene: (scene: number | 'end' | null) => void;
  activePlayer: string;
  setActivePlayer: (player: string) => void;
  selectedOption: InteractiveSceneOption | null;
  mainOverlay: JSX.Element | null;
  optionsCutsceneOverlay: JSX.Element | null;
  optionsAudioOverlay: JSX.Element | null;
  optionsVideoOverlay: JSX.Element | null;
  contextOverlay: JSX.Element | null;
  isPreview?: boolean;
  bandersnatchOverlay: JSX.Element | null;
  checkKeyOverlay: JSX.Element | null;
  producerPassOverlay?: JSX.Element | null;
  backButtonOverlay?: JSX.Element | null;
  isFullScreen?: boolean;
}) => {
  const [continueButtonPos, setContinueButtonPos] = useState(0);
  const {playedSeconds: timestamp} = usePlayerProgress();
  const {windowWidth, windowHeight} = useWindowSize();
  const {pause, play, toggleControls, seekTo} = usePlayer();
  useEffect(() => {
    setContinueButtonPos(
      getVideoPositionFromScreenTop(windowWidth, windowHeight) + 32
    );
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    const firstScene = parseInt(Object.keys(interactiveScenes)[0]);
    setNextScene(firstScene);
  }, []);

  useEffect(() => {
    if (activePlayer != 'interactive' && !selectedOption) {
      toggleControls(true);
      play();
    }
  }, [activePlayer, selectedOption]);

  useEffect(() => {
    if (!timestamp || nextScene == 'end') {
      return;
    }
    const ts = Math.ceil(timestamp * 5) / 5;

    if (nextScene?.timestamp == ts) {
      toggleControls(false);
      setActivePlayer('interactive');
      pause();
    }

    if (!nextScene) {
      setNextScene(getNextScene(timestamp, interactiveScenes));
    }
  }, [timestamp]);

  const continueButton =
    nextScene !== 'end' &&
    !nextScene?.unskippable &&
    !selectedOption &&
    !isPreview ? (
      <div
        className="fixed right-8 z-30"
        style={{
          bottom: `${continueButtonPos}px`,
        }}
      >
        <ButtonV2
          size="sm"
          text="Continue video"
          onClick={(e) => {
            e.preventDefault();
            seekTo(timestamp + 1);
            setActivePlayer('main');
          }}
        />
      </div>
    ) : null;

  return (
    <div
      className={cx(
        activePlayer == 'interactive' ? 'visible' : 'hidden',
        isFullScreen ? 'w-screen h-screen' : 'w-full h-full',
        'absolute top-0'
      )}
    >
      {mainOverlay}
      {optionsAudioOverlay}
      {optionsVideoOverlay}
      {contextOverlay}
      {optionsCutsceneOverlay}
      {continueButton}
      {bandersnatchOverlay}
      {checkKeyOverlay}
      {producerPassOverlay}
      {backButtonOverlay}
    </div>
  );
};
