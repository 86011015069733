import {useState, useRef, useCallback, useEffect} from 'react';

interface ProgressBarOptions {
  enabled?: boolean;
  duration: number;
}

export default function useInteractiveProgressBar({
  enabled = true,
  duration,
}: ProgressBarOptions) {
  const [percentage, setPercentage] = useState<number>(0);
  const requestRef = useRef<number>();
  const startTimeRef = useRef<number | null>(null);
  const isActiveRef = useRef<boolean>(true);

  const animate = useCallback(
    (time: number) => {
      if (!enabled) {
        requestRef.current = requestAnimationFrame(animate);
        return;
      }
      if (startTimeRef.current === null) {
        startTimeRef.current = time;
      }
      const elapsedTime = time - startTimeRef.current;
      const newPercentage = Math.min(100, (elapsedTime / duration) * 100);
      setPercentage(newPercentage);

      if (newPercentage <= 100 && isActiveRef.current) {
        requestRef.current = requestAnimationFrame(animate);
      }
    },
    [enabled]
  );

  // Function to start or resume the timer
  const startTimer = () => {
    if (percentage < 100) {
      isActiveRef.current = true;
      if (!requestRef.current) {
        requestRef.current = requestAnimationFrame(animate);
      }
    }
  };

  // Function to pause the timer
  const pauseTimer = () => {
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = undefined;
    }
    isActiveRef.current = false;
  };

  // Function to set the timer based on a percentage
  const setTimer = (newPercentage: number) => {
    const newElapsedTime = (newPercentage / 100) * duration;
    // Adjust the start time based on the new percentage
    startTimeRef.current = performance.now() - newElapsedTime;
    if (newPercentage < 100) {
      isActiveRef.current = true;
      requestRef.current = requestAnimationFrame(animate);
    } else {
      pauseTimer();
    }
    setPercentage(newPercentage);
  };

  // Function to reset the timer
  const resetTimer = () => {
    startTimeRef.current = null;
    setPercentage(0);
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }
  };

  useEffect(() => {
    if (isActiveRef.current) {
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [enabled]); // Empty dependency array means this effect runs once on mount

  return {timer: percentage, setTimer, startTimer, pauseTimer, resetTimer};
}
