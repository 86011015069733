import {usePrivy} from '@privy-io/react-auth';
import {useEffect, useState} from 'react';
import ButtonV2 from '@/components/redesign/ButtonV2';
import {ethers} from 'ethers';

const CheckKeyModal = ({handleLogin}: {handleLogin: (_b: boolean) => void}) => {
  const {login, user} = usePrivy();
  const [error, setError] = useState<string>('');
  const [allowed, setAllowed] = useState<boolean>(false);

  const goBack = async () => {
    handleLogin(false);
  };

  useEffect(() => {
    const checkKey = async () => {
      if (user?.wallet?.address) {
        try {
          const provider = new ethers.JsonRpcProvider(
            process.env.NEXT_PUBLIC_ETH_PROVIDER
          );
          const abi = [
            'function balanceOf(address owner) view returns (uint256)',
          ];
          if (!process.env.NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS) {
            setError('Unable to check your key balance.');
            return handleLogin(false);
          }
          const contract = new ethers.Contract(
            process.env.NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS,
            abi,
            provider
          );
          const balance = await contract.balanceOf(user.wallet.address);

          if (Number(balance) > 0) {
            setAllowed(true);
          }
          {
            setAllowed(false);
          }
        } catch (error) {
          console.error('Error checking key balance:', error);
          setError('Unable to check your key balance.');
          return handleLogin(false);
        }
      }
    };

    if (user) {
      checkKey();
    }
  }, [user]);

  const loginContent = !user && (
    <>
      <div className="shib-description">
        Let&apos;s check your key ownership.
      </div>
      <div className="flex gap-4">
        <ButtonV2
          text="Connect wallet"
          size="sm"
          className="w-fit"
          category="secondary-translucent"
          onClick={() => login()}
        />
        <ButtonV2
          text="Nevermind"
          size="sm"
          className="w-fit"
          category="secondary-translucent"
          onClick={() => handleLogin(false)}
        />
      </div>
    </>
  );

  const loggedInNoKey = user && !allowed && (
    <>
      <div className="shib-description">
        You don&apos;t have any keys in your wallet.
      </div>
      <ButtonV2
        text="Go back"
        category="secondary-translucent"
        size="sm"
        onClick={goBack}
      />
    </>
  );

  return (
    <div className="px-4 py-4 flex flex-col gap-2 items-center">
      {loginContent}
      {loggedInNoKey}
      {error ? <div className="text-red-400 text-xs">{error}</div> : null}
    </div>
  );
};

export default CheckKeyModal;
