import cx from 'classnames';
import {useEffect} from 'react';
import {twMerge} from 'tailwind-merge';

import {InteractiveSceneOption} from '@/components/player-v2/InteractiveVoting/types';
import {useWindowSize} from '@/utils/hooks/useWindowSize';
import {
  getVideoHeight,
  getVideoPositionFromScreenLeft,
  getVideoPositionFromScreenTop,
  getVideoWidth,
} from '@/utils/video-player';
import {BasePlayer} from '@/components/player-v2/BasePlayer';
import {usePlayer} from '@/components/player-v2/PlayerProvider';

type OptionOverlayVideoPlayerProps = {
  hoveredOption?: InteractiveSceneOption;
  option: InteractiveSceneOption;
  optionOverlayVideoClassName?: string;
  isFullScreen?: boolean;
};

export const Description = ({
  top,
  left,
  text,
  className,
}: {
  top: number;
  left: number;
  text: string;
  className?: string;
}) => {
  return (
    <div
      className={twMerge(
        'Text--TrebuchetMS absolute z-50 flex h-fit max-w-[10%] items-center rounded-sm bg-blue-950 bg-opacity-75 p-4 text-center text-xs text-shib-white backdrop-blur-md',
        className
      )}
      style={{
        top,
        left,
      }}
    >
      {text}
    </div>
  );
};

export const OptionOverlayVideoPlayer: React.FC<
  OptionOverlayVideoPlayerProps
> = ({hoveredOption, option, optionOverlayVideoClassName, isFullScreen}) => {
  const {isPlaying, togglePlay} = usePlayer();

  useEffect(() => {
    if (
      (hoveredOption === option && isPlaying) ||
      (hoveredOption !== option && !isPlaying)
    ) {
      return;
    }
    togglePlay();
  }, [hoveredOption]);

  const {windowWidth, windowHeight} = useWindowSize();

  if (!option.choiceOverlayVideoUrl) {
    return null;
  }

  let descriptionElement = <div />;

  if (option.description) {
    const descriptionPositionY =
      getVideoPositionFromScreenTop(windowWidth, windowHeight) +
      option.description?.y * getVideoHeight(windowWidth, windowHeight);

    const descriptionPositionX =
      getVideoPositionFromScreenLeft(windowWidth, windowHeight) +
      option.description?.x * getVideoWidth(windowWidth, windowHeight);
    descriptionElement = (
      <Description
        top={descriptionPositionY}
        left={descriptionPositionX}
        text={option.description?.text}
      />
    );
  }

  return (
    <div
      key={option.id}
      className={cx(
        isFullScreen ? 'h-screen w-screen' : 'h-full w-full',
        'pointer-events-none absolute inset-0',
        {
          hidden: hoveredOption !== option,
        },
        optionOverlayVideoClassName
      )}
    >
      <div
        style={{
          visibility:
            option?.description && hoveredOption == option
              ? 'visible'
              : 'hidden',
        }}
      >
        {descriptionElement}
      </div>
      <div
        className={cx(
          isFullScreen ? 'h-screen w-screen' : 'h-full w-full',
          'relative'
        )}
      >
        <BasePlayer
          isActive={hoveredOption === option}
          url={option.choiceOverlayVideoUrl}
          fullScreen={isFullScreen}
        />
      </div>
    </div>
  );
};
