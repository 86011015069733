export const ASPECT_RATIO = 1.7778;
export const PLAYER_CONTROLS_HEIGHT = 20;

export const getVideoPositionFromScreenTop = (
  windowWidth: number,
  windowHeight: number
) => {
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    return 0;
  } else {
    const videoHeight = windowWidth / ASPECT_RATIO;
    const videoTopPos = (windowHeight - videoHeight) / 2;
    return videoTopPos;
  }
};

export const getVideoPositionFromScreenLeft = (
  windowWidth: number,
  windowHeight: number
) => {
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    const videoWidth = ASPECT_RATIO * windowHeight;
    const videoLeftPos = (windowWidth - videoWidth) / 2;
    return videoLeftPos;
  } else {
    return 0;
  }
};

export const getVideoWidth = (windowWidth: number, windowHeight: number) => {
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    return ASPECT_RATIO * windowHeight;
  } else {
    return windowWidth;
  }
};

export const getVideoHeight = (windowWidth: number, windowHeight: number) => {
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    return windowHeight;
  } else {
    return windowWidth / ASPECT_RATIO;
  }
};
