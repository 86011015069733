import {Transition} from '@headlessui/react';
import React from 'react';

export const HideShow = ({
  shouldShow,
  children,
}: {
  shouldShow: boolean;
  children: JSX.Element;
}) => {
  return (
    <Transition
      show={shouldShow}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {children}
    </Transition>
  );
};

export default HideShow;
