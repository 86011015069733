import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {InteractiveSceneV2Config} from '../types';
import {filterNulls} from '../../InteractiveVoting/utils';

export function useAudioUrls(config: InteractiveSceneV2Config) {
  return useMemo(() => {
    let urls: string[] = [];
    for (const scene of config) {
      if (scene.during?.audio) {
        urls.push(scene.during?.audio.url);
      }
      if (scene.after?.audio) {
        urls.push(scene.after?.audio.url);
      }
      if (scene.areaChoices) {
        urls = urls.concat(
          filterNulls(
            scene.areaChoices.map((choice) => choice.during?.audio?.url)
          )
        );
        urls = urls.concat(
          filterNulls(
            scene.areaChoices.map((choice) => choice.after?.audio?.url)
          )
        );
      }

      if (scene.buttonChoices) {
        urls = urls.concat(
          filterNulls(
            scene.buttonChoices.map((choice) => choice.during?.audio?.url)
          )
        );
        urls = urls.concat(
          filterNulls(
            scene.buttonChoices.map((choice) => choice.after?.audio?.url)
          )
        );
      }
    }
    return urls;
  }, [config]);
}

export function useAudio(urls: string[]) {
  const [audioContext, setAudioContext] = useState<AudioContext | null>(null);
  const [soundMap, setSoundMap] = useState<Map<string, AudioBuffer>>(new Map());
  const [sources, setSources] = useState<Map<string, AudioBufferSourceNode>>(
    new Map()
  );
  const [loading, setLoading] = useState(false);
  const [currentSource, setCurrentSource] =
    useState<AudioBufferSourceNode | null>(null);

  // Initialize AudioContext and load buffers
  useEffect(() => {
    if (!audioContext) {
      setAudioContext(new AudioContext());
    }

    const loadBuffers = async () => {
      setLoading(true);
      const newSoundMap = new Map<string, AudioBuffer>();

      for (const url of urls) {
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        const buffer = await audioContext?.decodeAudioData(arrayBuffer);
        if (buffer) {
          newSoundMap.set(url, buffer);
        }
      }

      setSoundMap(newSoundMap);
      setLoading(false);
    };

    if (audioContext) {
      loadBuffers();
    }
  }, [urls, audioContext]);

  // Play audio by URL
  const playAudio = useCallback(
    (url: string, volume = 1.0) => {
      if (!audioContext) return;
      const buffer = soundMap.get(url);
      if (!buffer) return;

      // Stop the current source if it exists
      const currentSource = sources.get(url);
      if (currentSource) {
        currentSource.stop();
        sources.delete(url);
      }

      const source = audioContext.createBufferSource();
      source.buffer = buffer;

      const gainNode = audioContext.createGain();
      gainNode.gain.value = volume;
      gainNode.connect(audioContext.destination);

      source.connect(gainNode);
      source.start();
      setCurrentSource(source);

      const newSources = new Map(sources);
      newSources.set(url, source);
      setSources(newSources);

      // Optionally: handle source.onended to automatically remove it from the map
      source.onended = () => {
        newSources.delete(url);
        setSources(newSources);
      };
    },
    [audioContext, soundMap, sources]
  );

  // Stop audio by URL
  const stopAudio = useCallback(() => {
    currentSource?.stop();
    setCurrentSource(null);
    // const source = sources.get(url);
    // if (source) {
    //   source.stop();
    // }
  }, [sources]);

  return {playAudio, stopAudio, loading};
}

export function useAudioBuffer(audioContext: AudioContext) {
  const sources = useRef<
    Map<AudioBuffer, {source: AudioBufferSourceNode; gainNode: GainNode}>
  >(new Map());

  const playAudio = useCallback(
    (buffer: AudioBuffer, volume: number) => {
      // Stop the current buffer if it is already playing
      if (sources.current.has(buffer)) {
        sources.current.get(buffer)?.source.stop();
        // Optionally, if you don't plan to reuse the buffer soon or are managing memory tightly, uncomment the next line
        // sources.current.delete(buffer);
      }

      // Create a new source for the buffer
      const source = audioContext.createBufferSource();
      source.buffer = buffer;

      // Create and configure the gain node for volume control
      const gainNode = audioContext.createGain();
      gainNode.gain.value = volume;
      gainNode.connect(audioContext.destination);

      // Connect the source to the gain node and start playing
      source.connect(gainNode);
      source.start(0);

      // Update or add the source and gain node in the map
      sources.current.set(buffer, {source, gainNode});
    },
    [audioContext]
  );

  const stopAudio = useCallback((buffer: AudioBuffer) => {
    if (sources.current.has(buffer)) {
      // Stop the buffer if it is found
      sources.current.get(buffer)?.source.stop();
      // If you're reusing buffers frequently, you might keep them in the Map; otherwise, consider removing them here.
    }
  }, []);

  // Cleanup: Stop all sources and clear the map when the component unmounts
  useEffect(() => {
    return () => {
      sources.current.forEach(({source}) => source.stop());
      sources.current.clear();
    };
  }, []);

  return {playAudio, stopAudio};
}
