import {MouseEventHandler, useCallback, useEffect, useState} from 'react';
import {useMediaQuery} from '@react-hook/media-query';
import cx from 'classnames';
import dynamic from 'next/dynamic';

import {BasePlayer} from '@/components/player-v2/BasePlayer';
import {BasicPlayerOverlay} from '@/components/player-v2/BasicPlayerOverlay';
import {
  PlayerProvider,
  PlayerConfig,
} from '@/components/player-v2/PlayerProvider';
import {
  BtsConfig,
  InteractiveMomentType,
  PlayerUrl,
  ProjectVideoMetadata,
  SoundcloudTimestamp,
  SoundcloudTimestampType,
  TimelineElement,
} from '@/components/player-v2/types';

import {SoundcloudProgressBarOverlay} from '@/components/player-v2/SoundcloudProgressBarOverlay';
import {globalEmitter} from '@/components/player-v2/Emitter';
import {PlayerProgressProvider} from '@/components/player-v2/PlayerProgressProvider';
import {InteractiveScene} from '@/components/player-v2/InteractiveVoting/types';
import {InteractiveVotingOverlay} from '@/components/player-v2/InteractiveVoting/InteractiveVotingOverlay';
import {getNextScene} from '@/components/player-v2/InteractiveVoting/InteractiveVotingController';
import {PlayerVolumeProvider} from '@/components/player-v2/PlayerVolumeProvider';
import {InteractiveSceneV2Overlay} from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Overlay';
import {InteractiveSceneV2Config} from '@/components/player-v2/InteractiveSceneV2/types';
import {useTracker} from '@/components/analytics/TrackerProvider';

type InteractivePlayerProps = {
  // Metadata about the video.
  metadata: ProjectVideoMetadata;

  // An array of elements that should be rendered on the screen
  // based on their start and end time.
  timelineElements?: TimelineElement[];

  // Interactive moments
  interactionConfig?: {
    supportedInteractions: InteractiveMomentType[];
    interactiveScenes?: {[ts: string]: InteractiveScene};
    interactiveSceneV2?: InteractiveSceneV2Config;
  };

  // Featured timestamps on the video timeline
  soundcloudConfig?: {
    featuredTimestamps: {
      [ts: number]: SoundcloudTimestamp;
    };
  };

  // Behind the scene overlay config
  btsConfig?: BtsConfig;

  // The url of the video
  url: PlayerUrl;

  // When the Interactive Player is being played as a preview on a show page
  // vs stand-alone in a player, the controls and some overlay elements may
  // be rendered differently.
  overlayType?: 'basic' | 'preview' | 'eden';
  // Config for the preview overlay
  previewOverlayConfig?: {
    logo?: JSX.Element;
    onClickTrailer?: MouseEventHandler<HTMLButtonElement>;
    onClickJoin?: MouseEventHandler<HTMLButtonElement>;
    onScroll?: MouseEventHandler<HTMLButtonElement>;
  };

  fullScreen?: boolean;
  onTimeUpdate?: (time: number) => void;
} & PlayerConfig;

const BtsLazy = dynamic(() => import('./Bts/BehindTheScenesOverlay'), {
  ssr: false,
});
const PreviewOverlayLazy = dynamic(() => import('./PreviewPlayerOverlay'), {
  ssr: false,
});

export const InteractivePlayer: React.FC<InteractivePlayerProps> = ({
  backButtonBehavior,
  isMuted,
  isPlaying,
  controls,
  metadata,
  onEnded,
  shouldLoop,
  timelineElements,
  interactionConfig,
  soundcloudConfig,
  btsConfig,
  url,
  fullScreen = true,
  previewOverlayConfig = null,
  overlayType = 'basic',
  onTimeUpdate,
}) => {
  let interactionV2Overlay: JSX.Element | null = null;
  let soundcloudOverlay: JSX.Element | null = null;
  let onSeekRedirectTo: ((seconds: number) => number) | undefined = undefined;
  let btsOverlay: JSX.Element | null = null;
  let interactiveSceneOverlay;
  let onSeek: ((seconds: number) => void) | undefined = undefined;

  const isMobile = useMediaQuery('only screen and (max-width: 768px)');
  const [hideOnMobile, setHideOnMobile] = useState(false);
  useEffect(() => {
    setHideOnMobile(isMobile);
  }, [isMobile]);
  const tracker = useTracker();

  const [isInteractiveModeOn, setIsInteractiveModeOn] = useState<boolean>(true);

  const [showBts, setShowBts] = useState<boolean>(false);
  const [nextSceneIndex, setNextSceneIndex] = useState<number | 'end' | null>(
    null
  );

  const [activePlayer, setActivePlayer] = useState<string>('main');

  // supports the new interactive scenes
  const interactionV2Config = interactionConfig?.interactiveSceneV2;
  if (!!interactionV2Config) {
    interactionV2Overlay = (
      <InteractiveSceneV2Overlay
        metadata={metadata}
        config={interactionV2Config}
        isFullScreen={fullScreen}
      />
    );
  }

  const supportsSoundcloud =
    !isMobile &&
    isInteractiveModeOn &&
    interactionConfig?.supportedInteractions?.includes('soundcloud');

  if (supportsSoundcloud) {
    const featuredTimestamps = soundcloudConfig?.featuredTimestamps ?? {};
    soundcloudOverlay = (
      <SoundcloudProgressBarOverlay featuredTimestamps={featuredTimestamps} />
    );
  }

  const supportsBts =
    !isMobile &&
    isInteractiveModeOn &&
    interactionConfig?.supportedInteractions?.includes('bts');

  const onBtsBackButtonClick = useCallback(() => {
    setShowBts(false);
  }, []);

  // Interactive scenes overlay
  const supportsInteractiveScenes =
    !hideOnMobile &&
    isInteractiveModeOn &&
    interactionConfig?.supportedInteractions &&
    interactionConfig?.supportedInteractions.includes('scene');

  if (supportsBts && btsConfig) {
    btsOverlay = (
      <BtsLazy
        overlayVisible={showBts}
        setOverlayVisible={setShowBts}
        onBackButtonClick={onBtsBackButtonClick}
        btsConfig={btsConfig}
      />
    );
  }

  if (supportsInteractiveScenes && isInteractiveModeOn) {
    const interactiveScenes = interactionConfig?.interactiveScenes ?? {};

    let nextScene = nextSceneIndex ? interactiveScenes[nextSceneIndex] : null;

    onSeek = (seconds: number) => {
      setNextSceneIndex(getNextScene(seconds, interactiveScenes));
    };
    interactiveSceneOverlay = (
      <InteractiveVotingOverlay
        activePlayer={activePlayer}
        setActivePlayer={setActivePlayer}
        metadata={metadata}
        interactiveScenes={interactiveScenes}
        isPreview={overlayType == 'preview'}
        isEden={overlayType == 'eden'}
        nextScene={nextScene}
        setNextScene={setNextSceneIndex}
        isFullScreen={fullScreen}
      />
    );
  }

  useEffect(() => {
    const makeBtsVisible = (seconds: number, type: SoundcloudTimestampType) => {
      if (type === 'bts') {
        setShowBts(true);
      }
    };
    if (supportsBts) {
      globalEmitter.on('soundcloud', makeBtsVisible);
    }
    return () => {
      globalEmitter.off('soundcloud', makeBtsVisible);
    };
  }, [supportsBts]);

  let controlsOverlay;
  let previewControlsOverlay;
  if (
    (controls?.hasControls && overlayType == 'basic') ||
    overlayType == 'eden'
  ) {
    controlsOverlay = (
      <BasicPlayerOverlay
        className="absolute top-0 h-full w-full"
        metadata={metadata}
        timelineElements={timelineElements}
      />
    );
  }

  if (overlayType == 'preview' && previewOverlayConfig) {
    previewControlsOverlay = (
      <PreviewOverlayLazy config={previewOverlayConfig} />
    );
  }

  const handleTime = useCallback(
    (time: number) => {
      if (onTimeUpdate) {
        onTimeUpdate(time);
      }
    },
    [onTimeUpdate]
  );

  return (
    <PlayerVolumeProvider>
      <PlayerProvider
        backButtonBehavior={backButtonBehavior}
        controls={{
          hasControls:
            overlayType == 'basic' ||
            overlayType == 'eden' ||
            (supportsBts && !showBts),
          disabledControls: controls?.disabledControls,
        }}
        isInitiallyMuted={isMuted ?? false}
        isInitiallyPlaying={true}
        metadata={{
          ...metadata,
          variant: 'main',
        }}
        onSeekRedirect={onSeekRedirectTo}
        onSeek={(time) => {
          onSeek?.(time);
          tracker.capture('player.seek', {
            showId: metadata.projectId,
            player: 'main',
          });
          handleTime(time);
        }}
        onEnded={onEnded}
        shouldLoop={shouldLoop}
      >
        <PlayerProgressProvider
          projectId={metadata.projectId}
          playerId="main"
          fraction={4}
        >
          <div
            className={cx(
              fullScreen
                ? 'w-screen h-screen'
                : 'w-full h-full flex items-center justify-center',
              'relative'
            )}
          >
            <BasePlayer
              key="main-player"
              url={url}
              isActive={activePlayer === 'main'}
              fullScreen={fullScreen}
              onTimeUpdate={handleTime}
            />
            {controlsOverlay}
            {soundcloudOverlay}
            {btsOverlay}
            {interactiveSceneOverlay}
            {previewControlsOverlay}
            {interactionV2Overlay}
          </div>
        </PlayerProgressProvider>
      </PlayerProvider>
    </PlayerVolumeProvider>
  );
};
