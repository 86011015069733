import {useState, useEffect, useMemo} from 'react';
import {text} from 'stream/consumers';
import {fontSize} from 'tailwindcss/defaultTheme';
import InteractiveSceneV2Subtitle from '../InteractiveSceneV2Subtitle';
import {ASPECT_RATIO, PLAYER_CONTROLS_HEIGHT} from '@/utils/video-player';

const calculateSubtitlePos = (windowWidth: number, windowHeight: number) => {
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    const marginBottom = windowHeight / 12;
    return PLAYER_CONTROLS_HEIGHT + marginBottom;
  } else {
    const videoHeight = windowWidth / ASPECT_RATIO;
    const videoBottomPos = (windowHeight - videoHeight) / 2;
    const marginBottom = videoHeight / 12;
    return videoBottomPos + marginBottom;
  }
};

const calculateFontSize = (windowWidth: number, windowHeight: number) => {
  let videoHeight;
  if (windowWidth / windowHeight > ASPECT_RATIO) {
    videoHeight = windowHeight;
  } else {
    videoHeight = windowWidth / ASPECT_RATIO;
  }
  const size = videoHeight / 22;
  return size;
};

export function useSubtitle() {
  const [subtitle, setSubtitle] = useState<string | null>(null);
  const [subtitlePosition, setSubtitlePosition] = useState(
    calculateSubtitlePos(window.innerWidth, window.innerHeight)
  );
  const [subtitleSize, setSubtitleSize] = useState(16);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  function setSubtitleWithDuration(
    sub: string | null,
    durationSeconds: number
  ) {
    timeoutId && clearTimeout(timeoutId);
    setSubtitle(sub);
    if (durationSeconds === 0) {
      // do it immediately, not on next cycle
      setSubtitle(null);
    } else if (durationSeconds < Infinity) {
      const newTimeout = setTimeout(() => {
        setSubtitle(null);
      }, durationSeconds * 1000);
      setTimeoutId(newTimeout);
    }
  }

  // Listens to window size and updates font size and location accordingly
  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      const newFontSize = calculateFontSize(windowWidth, windowHeight);
      setSubtitlePosition(calculateSubtitlePos(windowWidth, windowHeight));

      setSubtitleSize(newFontSize);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const subtitleOverlay = useMemo(() => {
    return subtitle ? (
      <InteractiveSceneV2Subtitle
        text={subtitle}
        fontSize={subtitleSize}
        position={subtitlePosition}
      />
    ) : null;
  }, [subtitle]);

  return {
    setSubtitleWithDuration,
    subtitle,
    setSubtitle,
    subtitlePosition,
    setSubtitlePosition,
    subtitleSize,
    setSubtitleSize,
    subtitleOverlay,
  };
}
