import {useCallback, useEffect, useMemo, useState} from 'react';
import Image from 'next/image';
import {usePrivy} from '@privy-io/react-auth';

import {InteractiveVotingFullScreenPlayer} from '@/components/player-v2/InteractiveVoting/InteractiveVotingFullScreenPlayer';
import {
  InteractiveScene,
  InteractiveSceneOption,
} from '@/components/player-v2/InteractiveVoting/types';
import {PlayerProvider} from '@/components/player-v2/PlayerProvider';
import {InteractiveVotingController} from '@/components/player-v2/InteractiveVoting/InteractiveVotingController';
import OptionsCutsceneOverlay from '@/components/player-v2/InteractiveVoting/OptionsCutsceneOverlay';
import {OptionTapTargetWithAudioOverlays} from '@/components/player-v2/InteractiveVoting/OptionTapTargetWithAudioOverlays';
import {OptionOverlayVideoPlayer} from '@/components/player-v2/InteractiveVoting/OptionOverlayVideoPlayer';
import {SubtitleContainer} from '@/components/player/SubtitleOverlay';
import {useWindowSize} from '@/utils/hooks/useWindowSize';
import {
  getVideoPositionFromScreenLeft,
  getVideoPositionFromScreenTop,
} from '@/utils/video-player';
import {BandersnatchOverlay} from '@/components/player-v2/InteractiveVoting/BandersnatchOverlay';
import {ModalWrapper} from '@/components/modals/Modal';
import CheckKeyModal from '@/components/player-v2/InteractiveVoting/CheckKeyModal';
import {ArrowLeftIcon} from 'lucide-react';
import {
  useTempSession,
  useTempSessionActions,
} from '@/utils/auth/TempSessionProvider';
import ButtonV2 from '@/components/redesign/ButtonV2';
import toaster from '@/utils/toaster';
import {BugAntIcon} from '@heroicons/react/24/outline';
import {ProjectVideoMetadata} from '@/components/player-v2/types';
import {useHasWRKey} from '@/utils/white-rabbit/factions/useHasWRKey';

// This component is responsible for initializing state and memoizing the
// overlay components, to prevent unnecessary rerenders.
// State changes depending on timestamp and viewer interactions
// are accomplished in InteractiveVotingController
// which is passed these components
export const InteractiveVotingOverlay = ({
  activePlayer,
  setActivePlayer,
  nextScene,
  setNextScene,
  interactiveScenes,
  metadata,
  isPreview = false,
  isEden = false,
  isFullScreen = true,
}: {
  activePlayer: string;
  setActivePlayer: (player: string) => void;
  nextScene: InteractiveScene | 'end' | null;
  setNextScene: (scene: number | 'end' | null) => void;
  interactiveScenes: {[ts: number]: InteractiveScene};
  metadata: ProjectVideoMetadata;
  isPreview?: boolean;
  isEden?: boolean;
  isFullScreen?: boolean;
}) => {
  const [selectedOption, setSelectedOption] =
    useState<InteractiveSceneOption | null>(null);
  const [hoveredOption, setHoveredOption] = useState<InteractiveSceneOption>();

  const {isTempSessionValid} = useTempSession();
  const {clearSessionToken} = useTempSessionActions();

  const wrKeyCheck = useHasWRKey();

  const checkKeyModalOpen =
    wrKeyCheck.loading === false &&
    wrKeyCheck.hasWRKey === false &&
    selectedOption?.onSelect?.type === 'openModal' &&
    selectedOption?.onSelect?.modal === 'checkKey';

  // Used to position overlays
  const {windowWidth, windowHeight} = useWindowSize();
  const posScreenTop = getVideoPositionFromScreenTop(windowWidth, windowHeight);
  const posScreenLeft = getVideoPositionFromScreenLeft(
    windowWidth,
    windowHeight
  );

  const overlayRatio = windowWidth / 2000;

  let sceneOptions: InteractiveSceneOption[] = [];
  let sceneType = 'default';
  if (!nextScene || nextScene == 'end') {
    sceneOptions = [];
  } else {
    sceneOptions = Object.values(nextScene?.options) ?? [];
    sceneType = nextScene?.type ?? 'in-video';
  }

  const optionOverlayVideoClassName =
    nextScene && nextScene != 'end' && nextScene?.name == 'Episode 1'
      ? 'mix-blend-lighten'
      : 'mix-blend-normal';

  const infoOverlay = nextScene !== 'end' && nextScene?.popup && (
    <Image
      alt="Interactive scene popup"
      src={nextScene.popup.imageUrl}
      width={nextScene.popup.w * overlayRatio}
      height={nextScene.popup.h * overlayRatio}
      className="absolute flex"
      style={{
        top: posScreenTop + nextScene.popup.y * windowHeight,
        left:
          nextScene.popup.x == 'center'
            ? windowWidth / 2
            : posScreenLeft + nextScene.popup.x * windowWidth,
        transform:
          nextScene.popup.x == 'center' ? 'translate(-50%, 0)' : 'none',
      }}
    />
  );

  // COMPONENTS TO RENDER
  const choiceLoopOverlay = useMemo(() => {
    if (!nextScene || nextScene == 'end') {
      return null;
    }

    const shouldShowChoiceLoop =
      activePlayer == 'interactive' && (checkKeyModalOpen || !selectedOption);

    return (
      <div
        className={`${shouldShowChoiceLoop ? 'visible' : 'hidden'} relative`}
      >
        <PlayerProvider
          id="interactive-voting-loop-bg-video"
          key="interactive-voting-loop-bg-video"
          metadata={{
            ...metadata,
            variant: 'choice_loop',
          }}
          isInitiallyMuted
          isInitiallyPlaying={false}
          controls={{
            hasControls: false,
          }}
          shouldLoop
        >
          <InteractiveVotingFullScreenPlayer
            audioUrl={nextScene.audioUrl ?? ''}
            isPlaying={shouldShowChoiceLoop}
            videoUrl={nextScene.videoUrl}
            isFullScreen={isFullScreen}
          />
          {infoOverlay}
        </PlayerProvider>
      </div>
    );
  }, [
    nextScene,
    activePlayer,
    selectedOption,
    overlayRatio,
    checkKeyModalOpen,
  ]);

  const handleSelectOption = useCallback(
    async (option: InteractiveSceneOption | null) => {
      if ((await option?.getIsEligibleToSelect?.()) === false) {
        // Prompt purchasing producer pass if user doesn't have enough
        option?.onSelectIneligible?.();
        return;
      }
      setSelectedOption(option);
      setHoveredOption(undefined);
    },
    []
  );

  const optionsCutsceneOverlay = useMemo(() => {
    return (
      <OptionsCutsceneOverlay
        setActivePlayer={setActivePlayer}
        sceneOptions={sceneOptions}
        preCutsceneModalOpen={checkKeyModalOpen}
        selectedOption={selectedOption}
        setSelectedOption={handleSelectOption}
        isPreview={isPreview}
        setNextScene={setNextScene}
        isFullScreen={isFullScreen}
        metadata={metadata}
      />
    );
  }, [selectedOption, sceneOptions]);

  const optionsAudioOverlay = useMemo(() => {
    return (
      <OptionTapTargetWithAudioOverlays
        options={sceneOptions}
        onSelectOption={handleSelectOption}
        setHoveredOption={setHoveredOption}
        isFullScreen={isFullScreen}
      />
    );
  }, [sceneOptions]);

  const optionsVideoOverlay = useMemo(() => {
    return (
      <>
        {sceneType == 'in-video' &&
          sceneOptions.map((option) => (
            <PlayerProvider
              key={'Option overlay provider' + option.id}
              controls={{
                hasControls: false,
              }}
              metadata={{
                ...metadata,
                variant: 'choice_hover',
              }}
              isInitiallyMuted={true}
              isInitiallyPlaying={false}
            >
              <OptionOverlayVideoPlayer
                isFullScreen={isFullScreen}
                option={option}
                hoveredOption={hoveredOption}
                optionOverlayVideoClassName={optionOverlayVideoClassName}
                key={option.id}
              />
            </PlayerProvider>
          ))}
      </>
    );
  }, [hoveredOption, sceneOptions, sceneType]);

  const bandersnatchOverlay = useMemo(() => {
    if (nextScene == 'end') {
      return null;
    }
    return sceneType == 'bandersnatch' && !selectedOption ? (
      <BandersnatchOverlay
        question={nextScene?.name ?? ''}
        choices={nextScene?.options ?? {}}
        selected={selectedOption}
        setSelected={handleSelectOption}
      />
    ) : null;
  }, [nextScene, selectedOption]);

  const contextOverlay = useMemo(
    () => (hoveredOption ? <SubtitleContainer option={hoveredOption} /> : null),
    [hoveredOption]
  );

  const checkKeyOverlay = useMemo(() => {
    if (
      selectedOption?.onSelect?.type === 'openModal' &&
      selectedOption?.onSelect?.modal === 'checkKey'
    ) {
      return (
        <ModalWrapper
          backdrop="bg-opacity-[0.1]"
          visible={checkKeyModalOpen}
          size="sm"
        >
          <CheckKeyModal
            handleLogin={(success: boolean) => {
              if (!success) {
                setSelectedOption(null);
              }
            }}
          />
        </ModalWrapper>
      );
    }

    return null;
  }, [selectedOption, checkKeyModalOpen]);

  const backButtonOverlay = useMemo(() => {
    if (!nextScene || nextScene == 'end') {
      return null;
    }
    const resetDemo = () => {
      clearSessionToken();
      toaster.success('Demo reset');
    };

    const resetButton =
      isTempSessionValid && !selectedOption ? (
        <div className="ml-4">
          <ButtonV2
            iconLeft={<BugAntIcon className="w-4 h-4 mr-2" />}
            size="xs"
            text="reset demo"
            onClick={resetDemo}
          />
        </div>
      ) : (
        <div />
      );
    return isEden ? (
      <div className="absolute flex justify-between top-0 w-full left-0 p-5 bg-gradient-to-b from-black/75 to-black/0">
        <button onClick={nextScene.handleExit}>
          <ArrowLeftIcon className="w-8 h-8 sm:w-12 sm:h-12" />
        </button>
        {resetButton}
      </div>
    ) : null;
  }, [isEden, nextScene, selectedOption]);

  return (
    <InteractiveVotingController
      isPreview={isPreview}
      nextScene={nextScene}
      setNextScene={setNextScene}
      selectedOption={selectedOption}
      interactiveScenes={interactiveScenes}
      activePlayer={activePlayer}
      setActivePlayer={setActivePlayer}
      mainOverlay={choiceLoopOverlay}
      optionsCutsceneOverlay={optionsCutsceneOverlay}
      optionsAudioOverlay={optionsAudioOverlay}
      optionsVideoOverlay={optionsVideoOverlay}
      contextOverlay={contextOverlay}
      bandersnatchOverlay={bandersnatchOverlay}
      checkKeyOverlay={checkKeyOverlay}
      backButtonOverlay={backButtonOverlay}
      isFullScreen={isFullScreen}
    />
  );
};
