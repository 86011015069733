import React, {useEffect, useMemo, useState} from 'react';
import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {usePlayerVolume} from '@/components/player-v2/PlayerVolumeProvider';
import MemoizedInteractiveSceneV2AreaChoices from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2AreaChoices';
import {InteractiveSceneV2ButtonChoices} from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2ButtonChoices';
import InteractiveSceneV2Cutscene from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Cutscene';
import {InteractiveSceneV2} from '@/components/player-v2/InteractiveSceneV2/types';
import {useAudio} from '@/components/player-v2/InteractiveSceneV2/hooks/useAudio';
import {useSubtitle} from '@/components/player-v2/InteractiveSceneV2/hooks/useSubtitle';
import {ProjectVideoMetadata} from '@/components/player-v2/types';

type Props = {
  scene: InteractiveSceneV2;
  metadata: ProjectVideoMetadata;
  active: boolean;
  onSceneEnd: () => void;
  audioUrls: string[];
  isFullScreen?: boolean;
};

const MemoizedInteractiveSceneV2Player = React.memo(
  function InteractiveSceneV2Player({
    active,
    scene,
    metadata,
    onSceneEnd,
    audioUrls,
    isFullScreen = true,
  }: Props) {
    const {setSubtitle, subtitleOverlay, setSubtitleWithDuration} =
      useSubtitle();

    const {volume} = usePlayerVolume();
    const {playAudio, stopAudio} = useAudio(audioUrls);
    const {play, pause} = usePlayer();

    const [cutsceneUrl, setCutsceneUrl] = useState<string | null>(null);
    const [choicesActive, setChoicesActive] = useState(active);

    useEffect(() => {
      setChoicesActive(active);
    }, [active]);

    const buttonChoicesOverlay = useMemo(() => {
      return (
        <InteractiveSceneV2ButtonChoices
          active={
            choicesActive &&
            !!scene.buttonChoices?.length &&
            scene.buttonChoices?.length > 0
          }
          stopAudio={stopAudio}
          setSubtitle={setSubtitle}
          onChoiceEnd={(choice) => {
            let afterAudio = choice.after?.audio;
            let afterVideo = choice.after?.video;
            if (afterAudio) {
              setChoicesActive(false);
              if (afterAudio.subtitle) {
                setSubtitle(afterAudio.subtitle);
              }
              playAudio(afterAudio.url, volume);
              setTimeout(() => {
                setSubtitle(null);
                choice.after?.fn?.();
                onSceneEnd();
              }, afterAudio.duration * 1000);
            } else if (afterVideo) {
              setChoicesActive(false);
              // Pause the main video to let the cutscene play
              pause();
              setCutsceneUrl(afterVideo.url);
            } else {
              choice.after?.fn?.();
              onSceneEnd();
            }
          }}
          scene={scene}
          // selected,
          onChoiceClick={() => {}}
        />
      );
    }, [scene, choicesActive]);

    const buttonCutscenes = useMemo(() => {
      return scene.buttonChoices
        ? scene.buttonChoices.map((option, idx) => {
            const isPlaying = option.after?.video?.url === cutsceneUrl;
            return (
              <React.Fragment key={`buttoncutscene_${idx}`}>
                {option.after?.video ? (
                  <InteractiveSceneV2Cutscene
                    active={isPlaying}
                    url={option.after.video.url || ''}
                    loop={option.after.video.loop || false}
                    metadata={metadata}
                    shouldHideControls={
                      option.after.video.shouldHideControls || true
                    }
                    onSceneEnd={() => {
                      setCutsceneUrl(null);
                      option.after?.fn?.();
                      onSceneEnd();
                      play();
                    }}
                  />
                ) : null}
              </React.Fragment>
            );
          })
        : null;
    }, [scene.buttonChoices, cutsceneUrl]);

    const areaCutscenes = useMemo(() => {
      return scene.areaChoices
        ? scene.areaChoices.map((option, idx) => {
            const isPlaying = option.after?.video?.url === cutsceneUrl;
            return (
              <React.Fragment key={`areacutscene_${idx}`}>
                {option.after?.video ? (
                  <InteractiveSceneV2Cutscene
                    active={isPlaying}
                    metadata={metadata}
                    url={option.after.video.url || ''}
                    loop={option.after.video.loop || false}
                    shouldHideControls
                    onSceneEnd={() => {
                      setCutsceneUrl(null);
                      if (option.repeat) {
                        setChoicesActive(true);
                      } else {
                        option.after?.fn?.();
                        onSceneEnd();
                        play();
                      }
                    }}
                  />
                ) : null}
              </React.Fragment>
            );
          })
        : null;
    }, [scene.areaChoices, cutsceneUrl]);

    const areaChoicesActive =
      choicesActive &&
      !!scene.areaChoices?.length &&
      scene.areaChoices?.length > 0;

    const areaChoicesOverlay = useMemo(
      () => (
        <MemoizedInteractiveSceneV2AreaChoices
          active={areaChoicesActive}
          onSkip={() => {
            setChoicesActive(false);
            onSceneEnd();
            play();
          }}
          metadata={metadata}
          scene={scene}
          playAudio={playAudio}
          stopAudio={stopAudio}
          onChoiceEnd={(choice) => {
            setChoicesActive(false);
            let afterVideo = choice.after?.video;
            if (afterVideo) {
              setCutsceneUrl(afterVideo.url);
            } else {
              choice.after?.fn?.();
              onSceneEnd();
              play();
            }
          }}
          setSubtitleWithDuration={setSubtitleWithDuration}
          isFullScreen={isFullScreen}
        />
      ),
      [scene, choicesActive]
    );

    return (
      <>
        {buttonChoicesOverlay}
        {areaChoicesOverlay}
        {subtitleOverlay}
        {areaCutscenes}
        {buttonCutscenes}
      </>
    );
  }
);
export default MemoizedInteractiveSceneV2Player;
