import {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {usePrivy} from '@privy-io/react-auth';

const PROVIDER_URL = process.env.NEXT_PUBLIC_ETH_PROVIDER;

export const useHasWRKey = (): {
  hasWRKey: boolean | null;
  setHasWRKey: (value: boolean | null) => void;
  loading: boolean;
} => {
  const [hasWRKey, setHasWRKey] = useState<boolean | null>(null);
  const {user} = usePrivy();

  useEffect(() => {
    const checkWRKey = async () => {
      if (!process.env.NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS) {
        return;
      }
      if (user?.wallet?.address) {
        const provider = new ethers.JsonRpcProvider(PROVIDER_URL);
        const abi = [
          'function balanceOf(address owner) view returns (uint256)',
        ];
        const contract = new ethers.Contract(
          process.env.NEXT_PUBLIC_WR_KEY_CONTRACT_ADDRESS,
          abi,
          provider
        );
        const balance = await contract['balanceOf(address)'](
          user.wallet.address
        );
        if (Number(balance) > 0) {
          setHasWRKey(true);
        } else {
          setHasWRKey(false);
        }
      } else {
        setHasWRKey(false);
      }
    };
    checkWRKey();
  }, [user]);

  return {
    hasWRKey,
    setHasWRKey,
    loading: hasWRKey === null,
  };
};
