import {useCallback, useEffect, useMemo, useState} from 'react';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';
import {usePlayer} from '@/components/player-v2/PlayerProvider';
import InteractiveSceneV2Player from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Player';
import {
  InteractiveSceneV2,
  InteractiveSceneV2Config,
} from '@/components/player-v2/InteractiveSceneV2/types';
import {useAudioUrls} from '@/components/player-v2/InteractiveSceneV2/hooks/useAudio';
import {
  usePreloadVideos,
  useVideoUrls,
} from '@/components/player-v2/InteractiveSceneV2/hooks/useVideo';
import {ProjectVideoMetadata} from '@/components/player-v2/types';

export function InteractiveSceneV2Overlay({
  config,
  metadata,
  isFullScreen = true,
}: {
  config: InteractiveSceneV2Config;
  metadata: ProjectVideoMetadata;
  isFullScreen?: boolean;
}) {
  const {playedSeconds: timestamp} = usePlayerProgress();
  const {seekTo, toggleControls, pause, isPlaying, play} = usePlayer();

  // preload the audio
  const audioUrls = useAudioUrls(config);
  const videoUrls = useVideoUrls(config);
  usePreloadVideos(videoUrls);

  // Round to nearest .1 seconds
  const roundedToFifthSeconds = Math.round(timestamp * 5) / 5;
  const roundedTimestamp = roundedToFifthSeconds;

  // const roundedTimestamp = Math.floor(timestamp);
  const [nextSceneIndex, setNextSceneIndex] = useState(-1);
  const nextScene: InteractiveSceneV2 | undefined = useMemo(() => {
    return config[nextSceneIndex];
  }, [nextSceneIndex]);

  const onSceneEnd = useCallback(() => {
    if (!isPlaying) {
      if (nextScene && nextScene.duration === Infinity) {
        seekTo(nextScene.start + 1);
      }
      play();
    }
    setSceneActive(false);
    setNextSceneIndex(-1);
    toggleControls(true);
  }, []);

  const [sceneActive, setSceneActive] = useState(false);

  useEffect(() => {
    if (!timestamp) {
      return;
    }
    const ts = Math.ceil(timestamp * 5) / 5;
    // const ts = Math.ceil(timestamp);
    if (ts === nextScene?.start) {
      setSceneActive(true);
      // If the branch is active, don't allow user to interact with controls
      // to avoid conflicting seeks and avoid ausing in the middle of the added audio.
      toggleControls(false);
      // if it's an area choice overlay
      // or if the scene has no duration, then pause the main video
      if (nextScene.areaChoices?.length || nextScene.duration === Infinity) {
        pause();
      }
      return;
    }

    // otherwise if we're already past the next scene, we have to find whether or not there's a scene
    let newNextScene = -1;
    for (let i = 0; i < config.length; i++) {
      if (config[i].start > ts) {
        newNextScene = i;
        break;
      }
    }
    if (nextSceneIndex !== newNextScene && !sceneActive) {
      setNextSceneIndex(newNextScene);
    }
  }, [roundedTimestamp, timestamp, nextSceneIndex]);

  return (
    nextScene && (
      <InteractiveSceneV2Player
        metadata={metadata}
        isFullScreen={isFullScreen}
        active={sceneActive}
        audioUrls={audioUrls}
        scene={nextScene}
        onSceneEnd={onSceneEnd}
      />
    )
  );
}
