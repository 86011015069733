import {useEffect, useMemo} from 'react';
import {InteractiveSceneV2Config} from '../types';
import {filterNulls} from '../../InteractiveVoting/utils';

/**
 * Custom hook to preload video URLs.
 *
 * @param {string[]} videoUrls Array of video URLs to preload.
 */
export function usePreloadVideos(videoUrls: string[]) {
  useEffect(() => {
    if (!videoUrls || videoUrls.length === 0) {
      return;
    }

    // Create link elements for preloading videos
    const preloadLinks = videoUrls.map((url) => {
      const link = document.createElement('link');
      link.href = url;
      link.rel = 'preload';
      link.as = 'video';
      document.head.appendChild(link);
      return link;
    });

    // Cleanup function to remove the link elements when the component unmounts
    return () => {
      preloadLinks.forEach((link) => document.head.removeChild(link));
    };
  }, [videoUrls]); // Depend on the videoUrls array
}

export function useVideoUrls(config: InteractiveSceneV2Config) {
  return useMemo(() => {
    let urls: string[] = [];
    for (const scene of config) {
      if (scene.during?.video) {
        urls.push(scene.during?.video.url);
      }
      if (scene.after?.video) {
        urls.push(scene.after?.video.url);
      }
      if (scene.areaChoices) {
        urls = urls.concat(
          filterNulls(
            scene.areaChoices.map((choice) => choice.during?.video?.url)
          )
        );
        urls = urls.concat(
          filterNulls(
            scene.areaChoices.map((choice) => choice.after?.video?.url)
          )
        );
      }

      if (scene.buttonChoices) {
        urls = urls.concat(
          filterNulls(
            scene.buttonChoices.map((choice) => choice.during?.video?.url)
          )
        );
        urls = urls.concat(
          filterNulls(
            scene.buttonChoices.map((choice) => choice.after?.video?.url)
          )
        );
      }
    }
    return urls;
  }, [config]);
}
