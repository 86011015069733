import FadeIn from '@/components/FadeIn';
import React from 'react';

const MemoizedInteractiveSceneV2Subtitle = React.memo(
  function InteractiveSceneV2Subtitle({
    text,
    fontSize,
    position,
  }: {
    text: string;
    fontSize: number;
    position: number;
  }) {
    const posStyle = `${position}px`;
    return (
      <div
        className="items-end fixed z-10 min-h-screen flex w-full justify-center pointer-events-none"
        style={{
          bottom: posStyle,
        }}
      >
        <FadeIn delay={200}>
          <p className="Text--subtitle z-50 font-bold" style={{fontSize}}>
            {text}
          </p>
        </FadeIn>
      </div>
    );
  }
);

export default MemoizedInteractiveSceneV2Subtitle;
