/**
 * Returns the className string for the OptionOverlay component. This helps us determine the size of each overlay.
 */
export const getOptionOverlayClassName = (totalNumOptions: number) => {
  switch (totalNumOptions) {
    case 1:
      return 'w-full';
    case 2:
      return 'w-1/2';
    case 3:
      return 'w-1/3';
    case 4:
      return 'w-1/4';
    default:
      return 'w-full';
  }
};

export function filterNulls(nullables: (any | null)[]) {
  return nullables.flatMap((f) => (f ? [f] : []));
}
