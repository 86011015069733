import {InteractiveSceneOption} from '@/components/player-v2/InteractiveVoting/types';
import {useCallback, useEffect, useMemo, useState} from 'react';
import cx from 'classnames';
import {getOptionOverlayClassName} from '@/components/player-v2/InteractiveVoting/utils';
import {usePlayerVolume} from '@/components/player-v2/PlayerVolumeProvider';

export const OptionTapTargetWithAudioOverlays = ({
  options,
  onSelectOption,
  setHoveredOption,
  isFullScreen = true,
}: {
  options: InteractiveSceneOption[];
  onSelectOption: (option: InteractiveSceneOption) => void;
  setHoveredOption: (option: InteractiveSceneOption) => void;
  isFullScreen?: boolean;
}) => {
  const [playing, setPlaying] = useState<HTMLAudioElement | null>(null);
  const [optionIdToAudioMap, setOptionIdToAudioMap] = useState<{
    [id: string]: HTMLAudioElement;
  }>({});

  const {volume} = usePlayerVolume();

  const preloadAudioFile = (audioUrl: string) => {
    const audio = new Audio(audioUrl);

    audio.onpause = (e) => {
      audio.currentTime = 0;
    };
    audio.src = audioUrl;
    audio.volume = volume;
    return audio;
  };

  useEffect(() => {
    // Set optionIdToAudioMap to itself but with the volumes updated
    setOptionIdToAudioMap(
      Object.entries(optionIdToAudioMap).reduce((acc, [id, audio]) => {
        audio.volume = volume;
        return {...acc, [id]: audio};
      }, {})
    );
  }, [volume]);

  useEffect(() => {
    // TODO: Clean up audio when not needed anymore
    options.forEach((option) => {
      if (option.audioUrl && !optionIdToAudioMap[option.id]) {
        setOptionIdToAudioMap({
          ...optionIdToAudioMap,
          [option.id]: preloadAudioFile(option.audioUrl),
        });
      }
    });
  }, [options]);

  const optionOverlayClassName = getOptionOverlayClassName(options.length);

  const handleOptionClick = useCallback((option: InteractiveSceneOption) => {
    onSelectOption(option);
  }, []);

  const handleHover = useCallback(
    (option: InteractiveSceneOption) => {
      setHoveredOption(option);
      const url = option.audioUrl;
      if (!url) {
        return;
      }
      if (playing?.src != url) {
        const audio = optionIdToAudioMap[option.id];
        setPlaying(audio);
        audio?.play();
      }
    },
    [optionIdToAudioMap]
  );

  const handleMouseLeave = useCallback(() => {
    if (playing) {
      playing.pause();
      setPlaying(null);
    }
  }, [playing]);

  const tapTargetOverlays = useMemo(() => {
    return options.map((option) => {
      return (
        <button
          key={'overlay_' + option.id}
          className={optionOverlayClassName}
          onClick={() => handleOptionClick(option)}
          onMouseLeave={handleMouseLeave}
          onMouseOver={() => handleHover(option)}
        />
      );
    });
  }, [options]);

  return (
    <div
      className={cx(
        isFullScreen ? 'w-screen h-screen' : 'w-full h-full',
        'absolute inset-0 flex'
      )}
    >
      {tapTargetOverlays}
    </div>
  );
};
