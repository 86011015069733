import {useState, useEffect} from 'react';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';
import {InteractiveSceneV2, InteractiveSceneV2ButtonChoice} from './types';
import {twMerge} from 'tailwind-merge';
import InteractiveSceneV2Button from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Button';
import useInteractiveProgressBar from '@/components/player-v2/InteractiveSceneV2/hooks/useInteractiveProgressBar';

// the branching overlay with choice options
export function InteractiveSceneV2ButtonChoices({
  active,
  scene,
  onChoiceEnd,
  onChoiceClick,
  setSubtitle,
  stopAudio,
}: {
  active: boolean;
  stopAudio: (url: string) => void;
  scene: InteractiveSceneV2;
  onChoiceEnd?: (choice: InteractiveSceneV2ButtonChoice) => void;
  setSubtitle: (subtitle: string, durationSeconds: number) => void;
  onChoiceClick?: (choice: InteractiveSceneV2ButtonChoice) => void;
}) {
  const {timer} = useInteractiveProgressBar({
    duration: scene.duration * 1000,
    enabled: active && scene.duration !== Infinity,
  });
  const [selected, setSelected] =
    useState<InteractiveSceneV2ButtonChoice | null>(null);
  const {playedSeconds: timestamp} = usePlayerProgress();

  const [choiceSelectionFinished, setChoiceSelectionFinished] = useState(false);

  useEffect(() => {
    setChoiceSelectionFinished(false);
  }, [scene]);

  useEffect(() => {
    if (!timestamp || scene.duration == Infinity) {
      return;
    }

    const ts = Math.ceil(timestamp);
    // the choice selection ends
    if (
      ts > scene.start + scene.duration &&
      !choiceSelectionFinished &&
      active
    ) {
      let finalSelection = selected;
      if (!finalSelection) {
        finalSelection = scene.buttonChoices![scene.defaultChoice];
      }
      onChoiceEnd?.(finalSelection);
      setChoiceSelectionFinished(true);
    }
  }, [timestamp, onChoiceEnd, scene.start, scene.duration, selected, active]);

  const handleSelect = (choice: InteractiveSceneV2ButtonChoice) => {
    setSelected(choice);
    onChoiceClick?.(choice);
    if (scene.duration === Infinity) {
      onChoiceEnd?.(choice);
      setChoiceSelectionFinished(true);
    }
  };

  return scene.buttonChoices ? (
    <>
      <div
        className={twMerge(
          'min-w-full min-h-[calc(100vh-95px)] absolute inset-0 w-screen items-end flex justify-center z-20',
          active ? 'visible' : 'hidden'
        )}
      >
        <div className="fixed z-30 w-full pb-12 justify-center flex p-4 bg-gradient-to-t from-black to-transparent">
          <div
            className={twMerge(
              'w-full bg-opacity-75 absolute top-0 duration-500 h-1 rounded-sm bg-shib-white transition-none',
              scene.duration === Infinity ? 'hidden' : 'visible'
            )}
            style={{transform: `scale3d(${100 - timer}%, 1, 1)`}}
          />

          <div className="text--TrebuchetMS flex-col flex items-center justify-center gap-3 font-bold text-glow">
            {scene.title}
            <div className="grid grid-cols-2 grid-flow-row gap-4">
              {scene.buttonChoices.map((choice, index) => {
                const disabled = choice.disabled ? choice.disabled?.() : false;
                return (
                  <InteractiveSceneV2Button
                    key={index}
                    disabled={disabled}
                    selected={selected?.text === choice.text}
                    rejected={selected ? selected?.text !== choice.text : false}
                    text={choice.text}
                    onClick={() => {
                      handleSelect(choice);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null;
}
