import {useCallback, useEffect} from 'react';
import {PlayerProvider, usePlayer} from '@/components/player-v2/PlayerProvider';
import {OptionCutsceneVideoPlayer} from '@/components/player-v2/InteractiveVoting/OptionCutsceneVideoPlayer';
import {InteractiveSceneOption} from '@/components/player-v2/InteractiveVoting/types';
import {PlayerProgressProvider} from '@/components/player-v2/PlayerProgressProvider';
import {ProjectVideoMetadata} from '@/components/player-v2/types';

const OptionsCutsceneOverlay = ({
  metadata,
  sceneOptions,
  selectedOption,
  preCutsceneModalOpen,
  setActivePlayer,
  setSelectedOption,
  setNextScene,
  isPreview = false,
  isFullScreen = true,
}: {
  metadata: ProjectVideoMetadata;
  sceneOptions: InteractiveSceneOption[];
  selectedOption: InteractiveSceneOption | null;
  preCutsceneModalOpen: boolean;
  setActivePlayer: (player: string) => void;
  setSelectedOption: (option: InteractiveSceneOption | null) => void;
  isPreview?: boolean;
  setNextScene: (scene: number | null) => void;
  isFullScreen?: boolean;
}) => {
  const {playerRef, isMuted: isParentVideoMuted} = usePlayer(); // This is the parent player

  // When the cutscene ends, we continue back to the main player, except if a skipToTsOnEnd is provided
  const onSceneEnd = useCallback(() => {
    if (selectedOption?.endInteractionOnSelect) {
      if (
        selectedOption?.skipToTsOnEnd !== undefined &&
        selectedOption?.skipToTsOnEnd !== null
      ) {
        // For the BSOD scene that skips back to the beginning of the show
        playerRef.current?.seekTo(selectedOption.skipToTsOnEnd);
        // Wait for the seek to finish before setting the next scene
        setTimeout(() => {
          setSelectedOption(null);
          setNextScene(null);
          setActivePlayer('main');
        }, 500);
        return;
      } else {
        const mainPlayerTime = playerRef.current?.getCurrentTime() ?? 0;
        playerRef.current?.seekTo(mainPlayerTime + 1);
      }

      setSelectedOption(null);
      setNextScene(null);
      setActivePlayer('main');
    } else {
      setActivePlayer('interactive');
      setSelectedOption(null);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      selectedOption?.cutsceneVideoUrl === '' &&
      selectedOption?.endInteractionOnSelect
    ) {
      const mainPlayerTime = playerRef.current?.getCurrentTime() ?? 0;
      playerRef.current?.seekTo(mainPlayerTime + 1);
      setSelectedOption(null);
      setNextScene(null);
      setActivePlayer('main');
    }
  }, [selectedOption]);

  return (
    <>
      {sceneOptions.map((option) => {
        return (
          <div
            key={'cutscene_' + option.id}
            className="interactive-overlay absolute top-0 z-10"
            style={{
              visibility:
                !preCutsceneModalOpen && selectedOption?.id == option.id
                  ? 'visible'
                  : 'hidden',
            }}
          >
            <PlayerProvider
              id={`cutscene_${option.text}`}
              backButtonBehavior={{
                type: 'click',
                onClick: () => {
                  const mainPlayerTime =
                    playerRef.current?.getCurrentTime() ?? 0;
                  setSelectedOption(null),
                    playerRef.current?.seekTo(mainPlayerTime - 2);
                  setActivePlayer('main');
                },
              }}
              controls={{
                hasControls: !isPreview && option.cutsceneHasControls,
                disabledControls: option.cutsceneHasProgressBar
                  ? []
                  : ['progressBar'],
              }}
              metadata={{
                ...metadata,
                variant: 'cutscene',
              }}
              isInitiallyMuted={false}
              isInitiallyPlaying={false}
              onEnded={option.cutsceneEndingScreen ? () => {} : onSceneEnd}
            >
              <PlayerProgressProvider
                fraction={1}
                projectId={metadata.projectId}
                playerId="cutscene"
              >
                <OptionCutsceneVideoPlayer
                  id={option.id.toString()}
                  isSelected={
                    !preCutsceneModalOpen && selectedOption?.id == option.id
                  }
                  isFullScreen={isFullScreen}
                  isParentVideoMuted={isParentVideoMuted}
                  url={option.cutsceneVideoUrl}
                  cutsceneEndingScreen={option.cutsceneEndingScreen}
                />
              </PlayerProgressProvider>
            </PlayerProvider>
          </div>
        );
      })}
    </>
  );
};

export default OptionsCutsceneOverlay;
