import {useEffect} from 'react';
import useSound from 'use-sound';

export const usePlayInteractiveVotingSound = ({
  isPlaying,
  url,
}: {
  isPlaying: boolean;
  url: string;
}) => {
  const [play, {stop, sound}] = useSound(url, {loop: true});
  useEffect(() => {
    if (isPlaying) {
      sound?.fade(0, 1, 1000);
      play();
    } else {
      stop();
    }

    return stop;
  }, [isPlaying]);
};
