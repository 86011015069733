import {SoundcloudTimestamp} from '@/components/player-v2/types';
import {PLAYER_CONTROLS_HEIGHT} from '@/utils/video-player';
import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {useWindowSize} from '@/utils/hooks/useWindowSize';
import HideShow from '@/components/animations/HideShow';

import {useTracker} from '@/components/analytics/TrackerProvider';
import {usePlayerProgress} from '@/components/player-v2/PlayerProgressProvider';

const ANALYTICS_SURFACE = 'hol-bts';

export const SoundcloudProgressBarItem = ({
  pos,
  ts,
  timestampObj,
  seekTo,
  setSeconds,
  clickSoundFx,
}: {
  pos: number;
  ts: number;
  timestampObj: SoundcloudTimestamp;
  seekTo: (ts: number, shouldPlay: boolean) => void;
  setSeconds: (ts: number) => void;
  clickSoundFx: () => void;
}) => {
  const tracker = useTracker();
  const isBts = timestampObj.type == 'bts';

  if (isBts) {
    return (
      <div
        className="w-5 h-5 rounded-[10px] fixed flex items-center justify-center bg-white cursor-pointer hover:bg-shib-white"
        style={{
          left: pos,
        }}
        onClick={() => {
          if (timestampObj.type == 'bts') {
            clickSoundFx();
            seekTo(ts, true);
            setSeconds(ts);
            tracker.capture('bts.seek', {
              surface: ANALYTICS_SURFACE,
            });
          }
        }}
      ></div>
    );
  } else {
    return (
      <div
        className="transition-all hover:w-16 w-6 h-6 rounded-[8px] fixed flex items-center justify-center bg-gray-700 text-shib-white cursor-pointer hover:bg-gray-500"
        style={{
          left: pos,
        }}
      >
        {timestampObj.number ?? ''}
      </div>
    );
  }
};

export const SoundcloudProgressBarOverlay = ({
  featuredTimestamps,
}: {
  featuredTimestamps: {[key: number]: SoundcloudTimestamp};
}) => {
  const {
    playerRef,
    duration,
    hasStarted,
    isInteractingWithControls,
    hasControls,
    seekTo,
  } = usePlayer();

  const {setSeconds} = usePlayerProgress();

  // TODO: Can't load audio until the usePlayer() rerender issue is solved
  // here.

  // const audio =
  //   'https://storage.googleapis.com/shibuya-white-rabbit/whoosh-fx.mp3';
  // const [play] = useSound(audio, {
  //   loop: false,
  //   volume: 0.2,
  // });
  // setPlayAudioFx(play);

  // TODO: Allow this to fade in and out with the controls
  // by preventing usePlayer from rerendering the component every second lol

  const {windowWidth} = useWindowSize();

  if (!playerRef?.current || !duration) {
    return null;
  }

  return (
    <HideShow
      shouldShow={hasStarted && isInteractingWithControls && hasControls}
    >
      <div
        className="flex absolute h-5 w-full z-20"
        style={{
          bottom: PLAYER_CONTROLS_HEIGHT + 70,
        }}
      >
        {' '}
        {Object.keys(featuredTimestamps).map((tsString) => {
          const ts = Number(tsString);
          const timestampObj = featuredTimestamps[ts];
          const pos = (ts / 32) * windowWidth;
          return (
            <SoundcloudProgressBarItem
              key={ts}
              pos={pos}
              ts={ts}
              timestampObj={timestampObj}
              seekTo={seekTo}
              setSeconds={setSeconds}
              clickSoundFx={() => {}}
            />
          );
        })}
      </div>
    </HideShow>
  );
};
