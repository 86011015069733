import {InteractiveSceneOption} from '@/components/player-v2/InteractiveVoting/types';
import MemoizedInteractiveSceneV2Button from '@/components/player-v2/InteractiveSceneV2/InteractiveSceneV2Button';

export const BandersnatchOverlay = ({
  question,
  choices,
  selected,
  setSelected,
}: {
  question: string;
  choices: {
    [id: number]: InteractiveSceneOption;
  };
  selected: string | null;
  setSelected: (choice: InteractiveSceneOption) => void;
}) => {
  return (
    <div
      className={
        'min-w-full min-h-[calc(100vh-95px)] absolute inset-0 w-screen items-end flex justify-center z-20'
      }
    >
      <div className="fixed z-30 w-full pb-12 justify-center flex p-4 bg-gradient-to-t from-black to-transparent">
        <div className="text--TrebuchetMS flex-col flex items-center justify-center gap-3 font-bold text-glow">
          {question}
          <div className="grid grid-cols-2 grid-flow-row gap-4">
            {Object.keys(choices).map((choice, index) => {
              const option = choices[parseInt(choice)];
              return (
                <MemoizedInteractiveSceneV2Button
                  key={index}
                  selected={selected === option.text}
                  rejected={selected ? selected !== option.text : false}
                  text={option.text}
                  onClick={() => {
                    setSelected(option);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
