import {twMerge} from 'tailwind-merge';
import React, {useMemo} from 'react';

import {PlayerProgressProvider} from '@/components/player-v2/PlayerProgressProvider';
import {usePlayer, PlayerProvider} from '@/components/player-v2/PlayerProvider';
import {OptionCutsceneVideoPlayer} from '@/components/player-v2/InteractiveVoting/OptionCutsceneVideoPlayer';
import {
  BackButtonBehavior,
  ProjectVideoMetadata,
} from '@/components/player-v2/types';

const InteractiveSceneV2Cutscene = ({
  metadata,
  shouldHideControls,
  cutsceneEndingScreen,
  muted = false,
  onSceneEnd,
  loop = false,
  active = true,
  blend = false,
  isFullScreen = true,
  url,
}: {
  metadata: ProjectVideoMetadata;
  muted?: boolean;
  active?: boolean;
  shouldHideControls: boolean;
  cutsceneEndingScreen?: React.ReactNode;
  onSceneEnd?: () => void;
  loop?: boolean;
  blend?: boolean;
  isFullScreen?: boolean;
  url: string;
}) => {
  // console.log('Rerendering InteractiveSceneV2Cutscene', url);
  const {isMuted: isParentVideoMuted} = usePlayer();

  const isPlaying = active && !!url;

  const backButtonBehavior: BackButtonBehavior = useMemo(() => {
    return {
      type: 'click',
      onClick: () => {},
    };
  }, []);

  return (
    <div
      key={'cutscene_'}
      className={twMerge(
        `interactive-overlay absolute top-0 z-10 w-full h-full`,
        blend ? 'mix-blend-lighten' : 'mix-blend-normal',
        isPlaying ? 'visible' : 'hidden'
      )}
    >
      <PlayerProvider
        shouldLoop={loop}
        backButtonBehavior={backButtonBehavior}
        controls={{
          hasControls: !shouldHideControls,
          disabledControls: ['progressBar'],
        }}
        metadata={{
          ...metadata,
          variant: 'cutscene',
        }}
        isInitiallyMuted={false}
        isInitiallyPlaying={true}
        onEnded={cutsceneEndingScreen ? () => {} : onSceneEnd}
      >
        <PlayerProgressProvider
          projectId={metadata.projectId}
          playerId="cutscene"
          fraction={1}
        >
          <OptionCutsceneVideoPlayer
            isFullScreen={isFullScreen}
            playerProps={{className: 'bg-transparent', isActive: active}}
            forceMute={muted}
            isSelected={isPlaying}
            isParentVideoMuted={isParentVideoMuted}
            url={url}
            cutsceneEndingScreen={cutsceneEndingScreen}
          />
        </PlayerProgressProvider>
      </PlayerProvider>
    </div>
  );
};
export default InteractiveSceneV2Cutscene;
