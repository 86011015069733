import {useEffect} from 'react';

import {BasePlayer} from '@/components/player-v2/BasePlayer';
import {usePlayer} from '@/components/player-v2/PlayerProvider';
import {usePlayInteractiveVotingSound} from '@/components/player-v2/InteractiveVoting/usePlayInteractiveVotingSound';
import {PlayerUrl} from '@/components/player-v2/types';
type InteractiveVotingFullScreenPlayerProps = {
  audioUrl: string;
  isPlaying?: boolean;
  videoUrl: PlayerUrl;
  isFullScreen?: boolean;
};

export const InteractiveVotingFullScreenPlayer: React.FC<
  InteractiveVotingFullScreenPlayerProps
> = ({audioUrl, isPlaying = true, videoUrl: url, isFullScreen}) => {
  usePlayInteractiveVotingSound({
    isPlaying,
    url: audioUrl,
  });

  const {isPlaying: isVideoPlaying, togglePlay} = usePlayer();

  useEffect(() => {
    if ((isPlaying && !isVideoPlaying) || (!isPlaying && isVideoPlaying)) {
      togglePlay();
      return;
    }
  }, [isPlaying]);
  return (
    <BasePlayer url={url} isActive={isPlaying} fullScreen={isFullScreen} />
  );
};
